/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types"

import "../layout.css"



const Layout = ({ children }) => {
  return (
    <>
     
        <main>
            {children}
        </main>
        <footer className="__footer-bottom"
          style={{
            marginTop: `1rem`,
            marginBottom: `1rem`,
            textAlign: `center`,
            fontFamily: `Manrope`
          }}
        >
          © {new Date().getFullYear()}, Built with
          {` `}
          Jose Learning Center
        </footer>
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
